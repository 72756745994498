body {
  margin: 0;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f7;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  transition: background 0.2s ease-in-out;
}

body::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  background: #f5f5f7;
}
body::-webkit-scrollbar-track {
  background: #ebebeb;
}
body::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 24px;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  background: #f5f5f7;
}
.scrollbar::-webkit-scrollbar-track {
  border-radius: 24px;
  background: #ebebeb;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 24px;
}

#root {
  /* height: 100vh; */
  width: 100vw;
  position: relative;
}

* {
  outline: none;
  font-family: "Inter", "Helvetica Neue", sans-serif;
}

button {
  appearance: none;
  -webkit-appearance: button-bevel;
}

input[type="text"] {
  margin: 0;
  padding: 1px 2px;
  appearance: none;
  -webkit-appearance: none;
}

@media (max-width: 767px) {
  .chart-tooltip {
    width: 250px !important;
  }
}

.chart-tooltip-title {
  max-width: 100%;
  font-size: 18px;
  color: #262c34;
  font-weight: 700;
  word-wrap: break-word;
  white-space: wrap;
}

.chart-tooltip-text {
  font-size: 12px;
  color: #262c34;
  line-height: 1;
}

.tabulator .tabulator-tableholder {
  overflow: auto;
}
.tabulator .tabulator-tableholder::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f5f5f7;
}
.tabulator .tabulator-tableholder::-webkit-scrollbar-track {
  border-radius: 24px;
  background: #ebebeb;
}
.tabulator .tabulator-tableholder::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 24px;
}
.tabulator-row:hover {
  /* background-color: transparent !important; */
  background: #f6f7f7 !important;
}
.tabulator-row:hover > div {
  background: #f6f7f7 !important;
}

.tabulator-cell > .table-download-icon {
  display: none;
}
